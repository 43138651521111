<template>
  <main class="client">
    <Header />
    <slot />
    <Snackbar />
  </main>
</template>

<script setup lang="ts">
import Header from "@/components/Layout/Header.vue";
import { useUserModeStore } from "@/stores/useUserModeStore";
import Snackbar from "~/components/Common/Snackbar.vue";
import { useNotificationsStore } from "~/stores/useNotificationsStore";

const notificationsStore = useNotificationsStore();
const userModeStore = useUserModeStore();

onMounted(() => {
  notificationsStore.subscribeToNotificationsChannel();
  userModeStore.mountUserMode();
});
</script>

<style scoped lang="scss">
.client {
  padding-top: 60px;
}
</style>

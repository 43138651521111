<template>
  <header ref="target" class="header">
    <div class="header__wrapper">
      <BurgerMenu class="header__burger" v-if="userStore.isAuth" />
      <nuxt-link class="header__logo" to="/"
        ><IconLogo class="white-icon"
      /></nuxt-link>

      <div v-if="userStore.isAuth" class="header__links">
        <nuxt-link
          class="header__links-item"
          v-for="item in userModeStore.getBurgerLinks"
          :key="item.id"
          :to="item.link"
          >{{ item.name }}</nuxt-link
        >
      </div>

      <div class="header__action">
        <div v-if="sellerStore.isSeller">
          <v-btn
            class="header__action-button"
            size="large"
            color="white"
            density="compact"
            block
            @click="userModeStore.changeMode()"
            variant="outlined"
          >
            {{ userModeStore.getChangeButtonText }}
          </v-btn>
        </div>
        <div v-if="userStore.isAuth" class="header__user">
          <button @click="navigateTo('/notifications')">
            <v-badge :content="notificationsStore.notReadedNotifications"
              ><v-icon>mdi-bell-outline</v-icon></v-badge
            >
          </button>
        </div>

        <button @click="popIsOpen = !popIsOpen" class="header__action-account">
          <AvatarCustomer :size="24" white />
          <v-icon
            class="header__action-chevron"
            :class="{ 'header__action-chevron--active': popIsOpen }"
            >mdi-chevron-down</v-icon
          >
        </button>
      </div>
    </div>
  </header>

  <div class="header__popup-wrapper">
    <HeaderAccountPopUp :popIsOpen="popIsOpen" />
  </div>
</template>

<script setup lang="ts">
import BurgerMenu from "@/components/Layout/BurgerMenu.vue";
import IconLogo from "@/components/Icons/IconLogo.vue";
import HeaderAccountPopUp from "~/components/Layout/HeaderAccountPopUp.vue";
import { onClickOutside } from "@vueuse/core";
import AvatarCustomer from "~/components/AvatarCustomer.vue";
import { useUserModeStore } from "@/stores/useUserModeStore";
import { useSellerStore } from "@/stores/useSellerStore";
import { useNotificationsStore } from "@/stores/useNotificationsStore";

const userStore = useUserStore();
const sellerStore = useSellerStore();
const userModeStore = useUserModeStore();
const notificationsStore = useNotificationsStore();

const popIsOpen = ref(false);

watch(
  () => popIsOpen.value,
  () => {
    const body = document.querySelector("body");
    body.style.position = popIsOpen.value ? "fixed" : "static";
  }
);

const target = ref(null);

onClickOutside(target, () => {
  popIsOpen.value = false;
});
</script>

<style scoped lang="scss">
.header {
  background: var(--color-main-dark);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  height: var(--header-default-height);

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__popup-wrapper {
    max-width: 1200px;
    width: 100%;
    height: 0;
    margin: 0 auto;
    position: relative;
  }

  &__burger {
    display: none;

    @media (max-width: 1200px) {
      display: block;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  &__links-item {
    color: var(--color-main-white);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;
  }

  &__logo {
    display: flex;
    @media (max-width: 1200px) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__action,
  &__user {
    display: flex;
    gap: 12px;
    color: var(--color-main-white);

    a {
      color: var(--color-main-white);
    }
  }

  &__action-account {
    display: flex;
    align-items: center;
  }

  &__action {
    display: flex;
    align-items: center;
    @media (max-width: 1200px) {
      margin-left: auto;
    }
  }

  &__action-chevron {
    transition: 0.3s;

    &--active {
      transform: rotate(-180deg);
    }
  }

  &__action-button {
    font-size: 14px;

    @media (max-width: 1200px) {
      display: none;
    }
  }
}
</style>
